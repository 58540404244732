import React, { Component } from "react";


import Layout from "../components/layout"
import Banner from "../components/others/banner"
import CardNews from "../components/others/cardNew"

import { StaticQuery, graphql } from "gatsby"
import ReactMarkdown from 'react-markdown'

import Moment from "moment";
import es from "moment/locale/es";
import AliceCarousel from 'react-alice-carousel';
import Slider from "react-slick";

import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import ScrollAnimation from 'react-animate-on-scroll';
import "animate.css/animate.min.css";


Moment.locale("es", es);



const objectGraphql = graphql`
{
  strapiSostenibilidad(locale: {eq: "es-PE"}) {
    Banner {
      fondo {
        url
      }
      url {
        enlace
        titulo
        id
      }
      titulo
      id
    }
    carrusel {
      simple {
        descripcion
        id
        titulo
      }
      simple2 {
        descripcion
        id
        img {
          url
        }
      }
      id
    }
  }
  allStrapiNoticias(
    filter: {locale: {eq: "es-PE"}, Categorys: {nombre: {eq: "Sostenibilidad"}}}
  ){
    edges {
      node {
        id
        titulo
         
        Tags {
          nombre
          id
        }
        Categorys {
         nombre
       }
        fecha
        min_descripcion
        descripcion
        imagen {
          url
        }
      }
    }
  }
  allStrapiHome(filter: {locale: {eq: "es-PE"}}) {
    edges {
      node {
        Cantidad_noticias
      }
    }
  }
}

`


class Sostenibilidad extends Component {
 news = (data) => {
    let array = data.allStrapiNoticias.edges;
    let sortedArray = array.sort((a, b) => new Moment(a.node.fecha).format('YYYYMMDD') - new Moment(b.node.fecha).format('YYYYMMDD'))
    // let aNuevo = sortedArray.slice(sortedArray.length - (data.allStrapiHome.edges[0].node.Cantidad_noticias))
    let NewsortedArray = sortedArray.sort((a, b) => new Moment(b.node.fecha).format('YYYYMMDD') - new Moment(a.node.fecha).format('YYYYMMDD'))


    // let copyNewsortedArray= [...NewsortedArray];

    // let allArraypta=NewsortedArray.concat(copyNewsortedArray);
    // let allArrayptb=NewsortedArray.concat(allArraypta);
return NewsortedArray.map((item) => {
      return (
        <CardNews  props={this.props.props} data={item.node}  />
      )
    })
}
  render() {

    var settings = {
      dots: true,
      infinite: false,
  speed: 100,
  slidesToShow: 3,
  slidesToScroll: 3,
  

  responsive: [
    
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        initialSlide: 2
      }
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1
      }
    }
  ],
};
    return (
      <StaticQuery
        query={objectGraphql}
        render={data => (
          <React.Fragment>
            <Layout nameTab='Sostenibilidad' props={this.props.props}>
              <Banner banner={data.strapiSostenibilidad.Banner} />
              <section className="padding-section-int" id="sostenibilidad1">
                <div className="container">
                  {data.strapiSostenibilidad.carrusel.map((itemCarrusel, index) => {
                    return (
                    <ScrollAnimation   animateIn="fadeInUp">

                      <div key={index}   className="row wow fadeInUp margin-b-80" >
                        <div className="col-md-3">
                          <h3>{itemCarrusel.simple.titulo}</h3>

                          <ReactMarkdown>{itemCarrusel.simple.descripcion}</ReactMarkdown>
                        </div>
                        <div id="gestion" className="col-md-9 sustuntability">
                          {
                            index < 2 ?

                              <AliceCarousel mouseTracking
                                responsive={{
                                  0: { items: 1 },
                                  568: { items: 2 },
                                  1024: { items: 3 },
                                }}
                                controlsStrategy="alternate"
                                infinite

                                autoPlayStrategy="none"
                                autoPlayInterval={3500}
                                animationDuration={500}
                                animationType="fadeout"
                                keyboardNavigation
                                disableDotsControls
                              >
                                {
                                  itemCarrusel.simple2.map((item) => {
                                    return (
                                      <div style={{ marginRight: '20px' }} className="item" >
                                        <p>
                                            <img className="card-img-top img-fluid" src={item.img.url} alt={item.img.url} />
                                        </p>
                                        <ReactMarkdown style={{ lineHeight: "1.6" }} >{item.descripcion}</ReactMarkdown>
                                      </div>
                                    )
                                  })
                                }

                              </AliceCarousel>
                              :
                              <AliceCarousel mouseTracking
                                responsive={{
                                  0: { items: 1 },
                                  568: { items: 2 },
                                }}
                                controlsStrategy="alternate"

                                autoPlayStrategy="none"
                                autoPlayInterval={3500}
                                animationDuration={500}
                                animationType="fadeout"
                                keyboardNavigation
                                disableDotsControls
                                disableButtonsControls
                              >
                                {
                                  itemCarrusel.simple2.map((item) => {
                                    return (
                                      <div style={{ marginRight: '20px' }} className="item" >
                                        <p>
                                            <img className="card-img-top img-fluid" src={item.img.url} alt={item.img.url} />
                                        </p>
                                        <ReactMarkdown style={{ lineHeight: "1.6" }} >{item.descripcion}</ReactMarkdown>
                                      </div>
                                    )
                                  })
                                }
                             </AliceCarousel>
                          }</div>
                      </div>
                    </ScrollAnimation>
                    )
                  })}

                  <div className="row">
                    <div className="col-md-12 titulo-center text-center">
                      <h2>


                        Noticias


                      </h2>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <div id="notas">
                        <Slider {...settings}>
                        {this.news(data)}
                        </Slider>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12 titulo-center text-center">
                      <p>
                        <a href="/es/noticias" className="btn btn-primary">
                          <i className="fa fa-file-o"
                          ></i>  Ver más noticias
                        </a>
                      </p>
                    </div>
                  </div>
                </div>
              </section>
            </Layout>
          </ React.Fragment>
        )}
      />
    )
  }
}

export default Sostenibilidad
